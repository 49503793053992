import { render, staticRenderFns } from "./ai_generate_issue_description.vue?vue&type=template&id=0a95deb1"
import script from "./ai_generate_issue_description.vue?vue&type=script&lang=js"
export * from "./ai_generate_issue_description.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports